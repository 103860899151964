export function findChord(arr: number[]) {
  const arrCopy = [...arr];
  const arrCopyReversed = arrCopy.reverse();

  // Setting any muted string to -1 to keep just one value for mutes strings
  for (let i = 0; i < arrCopyReversed.length; i++) {
    if (arrCopyReversed[i] < 0 || Object.is(arrCopyReversed[i], -0)) {
      arrCopyReversed[i] = -1;
    }
  }

  const chordStr = arrCopyReversed.toString();

  const chordMap = new Map([
    ["-1,0,2,2,2,0", "A"],
    ["0,0,2,2,2,0", "~A"],
    ["-1,0,2,1,2,0", "Amaj7"],
    ["0,0,2,1,2,0", "~Amaj7"],
    ["-1,0,2,0,2,0", "A7"],
    ["0,0,2,0,2,0", "~A7"],
    ["-1,0,2,2,1,0", "Am"],
    ["0,0,2,2,1,0", "~Am"],
    ["-1,0,2,0,1,0", "Am7"],
    ["0,0,2,0,1,0", "~Am7"],
    ["-1,-1,4,4,4,2", "B"],
    ["0,0,4,4,4,2", "~B"],
    ["2,2,1,3,0,-1", "Bmaj7"],
    ["2,2,1,3,0,0", "~Bmaj7"],
    ["-1,2,1,2,0,2", "B7"],
    ["-1,2,1,2,0,2", "~B7"],
    ["-1,-1,4,4,3,2", "Bm"],
    ["0,0,4,4,3,2", "~Bm"],
    ["-1,2,0,2,0,2", "Bm7"],
    ["0,2,0,2,0,2", "~Bm7"],
    ["-1,3,2,0,1,0", "C"],
    ["0,3,2,0,1,0", "~C"],
    ["-1,3,2,0,0,0", "Cmaj7"],
    ["0,3,2,0,0,0", "~Cmaj7"],
    ["-1,3,2,3,1,0", "C7"],
    ["0,3,2,3,1,0", "~C7"],
    ["-1,3,1,0,-1,-1", "Cm"],
    ["0,3,1,0,0,0", "~Cm"],
    ["-1,3,1,3,-1,-1", "Cm7"],
    ["0,3,1,3,0,0", "~Cm7"],
    ["-1,-1,0,2,3,2", "D"],
    ["0,0,0,2,3,2", "~D"],
    ["-1,-1,0,2,2,2", "Dmaj7"],
    ["0,0,0,2,2,2", "~Dmaj7"],
    ["-1,-1,0,2,1,2", "D7"],
    ["0,0,0,2,1,2", "~D7"],
    ["-1,-1,0,2,3,1", "Dm"],
    ["0,0,0,2,3,1", "~Dm"],
    ["-1,-1,0,2,1,1", "Dm7"],
    ["0,0,0,2,1,1", "~Dm7"],
    ["0,2,2,1,0,0", "E"],
    ["0,2,1,1,0,0", "Emaj7"],
    ["0,2,0,1,0,0", "E7"],
    ["0,2,2,0,0,0", "Em"],
    ["0,2,2,0,3,0", "Em7"],
    ["-1,-1,3,2,1,1", "F"],
    ["0,0,3,2,1,1", "~F"],
    ["1,3,3,2,1,1", "~F"],
    ["-1,-1,3,2,1,0", "Fmaj7"],
    ["0,0,3,2,1,0", "~Fmaj7"],
    ["1,3,1,2,1,1", "F7"],
    ["1,3,1,2,1,1", "~F7"],
    ["-1,-1,3,1,1,1", "Fm"],
    ["0,0,3,1,1,1", "~Fm"],
    ["1,3,1,1,1,1", "Fm7"],
    ["3,2,0,0,0,3", "G"],
    ["3,-1,0,0,0,2", "Gmaj7"],
    ["3,0,0,0,0,2", "~Gmaj7"],
    ["3,2,0,0,0,1", "G7"],
    ["-1,-1,0,3,3,3", "Gm"],
    ["0,0,0,3,3,3", "~Gm"],
    ["-1,1,3,0,3,0", "Gm7"],
    ["0,1,3,0,3,0", "~Gm7"],
    ["2,4,4,2,2,2", "F#m (Bar)"],
    ["3,5,5,3,3,3", "Gm (Bar)"],
    ["4,6,6,4,4,4", "G#m (Bar)"],
    ["5,7,7,5,5,5", "Am (Bar)"],
    ["6,8,8,6,6,6", "A#m (Bar)"],
    ["7,9,9,7,7,7", "Bm (Bar)"],
    ["8,10,10,8,8,8", "Cm (Bar)"],
    ["9,11,11,9,9,9", "C#m (Bar)"],
    ["10,12,12,10,10,10", "Dm (Bar)"],
    ["11,13,13,11,11,11", "D#m (Bar)"],
    ["12,14,14,12,12,12", "Em (Bar)"],
    ["13,15,15,13,13,13", "Fm (Bar)"],
  ]);

  return chordMap.get(chordStr);
}
